<template>
  <div ref="fieldWrap" class="flex items-start transition-ease" :class="getWrapClass">
    <div v-if="isShowLabel" :style="getLabelStyle" class="flex justify-center items-start h-full">
      <label ref="formFieldLabel" :class="getLabelClass" v-html="label"></label>
    </div>
    <div class="relative flex flex-col h-full" :class="getFieldWrapClass">

      <slot :value="getParentValue" :handler="handleInput">
        <div style="min-height: 40px;" class="items-center">
          <div v-if="isValue" class="py-1 px-2 text-right h-full">{{ getParentValue }}</div>
          <component v-else :is="getInputComponent"
                     v-bind="$attrs"
                     :name="field"
                     :auto-max="autoMax" :type="getInputType"
                     :value="getParentValue"
                     @input="handleInput"
                     @change="handleChange"
                     @keypress.enter="handleEnterKey"
                     :options="(options) ? options : null"
                     :rows="rows ? rows : null"
                     single
                     :before-days="(options && options.beforeDays) ? options.beforeDays : 1"
                     :select-group="options && options.selectGroup"
                     class="w-full form-field-input"

          ></component>
        </div>
      </slot>
      <div v-for="msg in getErrorMessage" class="p-2 right-0 text-red-600 transition-ease"
           :class="getErrorMessageCSS">
        {{ msg }}
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'EvFormField',
  inject: ['evForm'],
  props: {
    value: null,
    label: null,
    field: null,
    comment: null,
    /**
     *
     * @values input,textarea,checkbox,radio-group,select,datepicker,date-range
     */
    type: {
      type: String,
      default: 'text'
    },
    labelPosition: {
      default: 'left'
    },
    // 텍스트의 길이에 따라 인풋의 크기가 가변합니다.
    autoMax: {
      default: null
    },
    options: null,
    extInput:null,
    rows:null,
    //
    required:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errorMessage: null,
      labelWidth: null,
      animated: null
    }
  },
  computed: {
    isMobile(){
      return window.innerWidth < 500;
    },
    isBordered() {
      return this.evForm.bordered;
    },
    isNoLabel() {
      return this.evForm.noLabel;
    },
    getParentValue() {
      if (typeof this.value !== 'undefined') {
        return this.value;
      }
      if (!this.field) {
        return;
      }
      return this.evForm.getFieldValue(this.field);
    },
    hasSlot() {
      return !!this.$slots.default;
    },
    hasError() {
      return !!this.getErrorMessage;
    },
    getErrorMessage() {

      return this.evForm.getErrors(this.field);
    },
    hasComment() {
      return !!this.getCommentMessage && !this.hasError;
    },
    getCommentMessage() {
      return this.comment;
    },
    getInputType() {
      return this.type ? this.type : 'text';
    },
    isInput() {
      return ['text', 'password', 'number'].includes(this.type);
    },
    isCheckbox() {
      return this.type === 'checkbox'
    },
    getLabelStyle() {
      if (this.isLabelTop) {
        return {
          width: '100%',
          justifyContent: 'start'
        }
      }
      if (!this.labelWidth) {
        return {};
      }
      return {width: this.labelWidth, 'flex-shrink': 0}
    },
    getFieldWrapClass() {
      return {'is-required': this.required, 'has-error': this.hasError, 'w-full': this.labelWidth, 'border-l': this.isBordered}
    },
    getParentWrapClass() {
      if (this.hasError) {
        return ['items-start']
      } else {
        return ['items-center']
      }
    },
    getErrorMessageCSS() {
      if (this.hasError) {
        setTimeout(() => {
          this.animated = true;
        }, 10);
        return (this.animated) ? ['opacity-100'] : ['opacity-0']
      } else {
        this.animated = false;
        return ['hidden']
      }
    },
    isValue() {
      return this.type === 'value';
    },
    isDatePicker() {
      return this.type === 'datepicker';
    },
    getWrapClass() {
      if (this.isBordered) {
        return ['border-l', 'border-r', 'border-b', 'first:border-t'];
      }
      if (this.isLabelTop) {
        return ['flex-col']
      }
      return ['mb-1']
    },
    getLabelClass() {
      let classes = ['text-center', 'block', 'text-main-400', 'text-sm', 'px-2', 'py-1', 'form-field-label'];
      if (this.isLabelTop) {
        classes[0] = 'text-left';
        return classes;
      }
      return classes;
    },
    /**
     *
     * @return boolean
     */
    isLabelTop() {
      return this.evForm && this.evForm.isLabelTop();
    },
    isDateRangePicker() {
      return this.type === 'daterange'
    },
    isShowLabel() {

      if (this.isNoLabel) {
        return false;
      }
      return typeof this.label !== 'undefined';
    },
    getInputComponent() {

      switch (this.type) {
        case 'input':
          return 'ev-input';
        case 'textarea':
          return 'ev-textarea';
        case 'checkbox':
          return 'ev-check-box';
        case 'radio-group':
          return 'ev-radio-group';
        case 'select':
          return 'ev-tag-select';
        case 'datepicker':
          return 'ev-datepicker';
        case 'date-range':
          return 'ev-date-range';
        case 'switch':
          return 'ev-switch';
        default:

          return 'ev-input';
      }
    }
  },
  methods: {
    /**
     * 지역적으로 추가된 컴포넌트를 찾아 폼 필드에서도 사용할 수 있게 합니다.
     *
     * @param {Vue} component
     * @param {string} name
     * @returns {boolean|*}
     */
    searchComponent(component, name){
      let localComp = component.$options.components[name];
      if(localComp){
        this.$options.components[name] = localComp;
        return name;
      }else{
        if(component.evForm){
          return this.searchComponent(component.evForm, name);
        }else{
          return false;
        }
      }
    },

    clearErrorMessage() {
      this.evForm.clearError(this.field);
    },
    handleInput(v) {
      if (typeof this.field === 'undefined') {
        return;
      }
      if (typeof this.value !== 'undefined') {
        this.$emit('input', v);
      }

      this.evForm.setFieldValue(this.field, v);
    },
    setLabelWidth(width) {
      this.labelWidth = (width + 5) + 'px';
    },
    handleChange(v) {
      this.clearErrorMessage();
    },
    handleEnterKey() {

      if (this.evForm.submitHandler && typeof this.evForm.submitHandler === 'function') {
        this.evForm.submitHandler();
      }
    }
  },
  mounted() {
    this.evForm.alignLabels();
  }
}
</script>
<style>
  .is-required .form-field-input{
    @apply border-orange-300
  }
</style>
